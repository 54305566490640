<div class="flip-container">
    <div class="flip-card {{flipCard.CssClasses ?? ''}}" [ngClass]="{ 'flip-slide': isSlide }">
        <div class="front">
            <app-images class="w-100" *ngIf="flipCard.FrontBackgroundImage" [Image]="flipCard.FrontBackgroundImage"></app-images>
            <div
                class="overlay-text"
                [innerHTML]="flipCard.OverlayText | embed: 'html'"
            ></div>
        </div>

        <div
            class="back"
            setBackgroundImage
            [imageUrl]="flipCard.BackBackgroundImage?.ImageUrl ?? ''"
        >
            <div
                class="overlay-text"
                [innerHTML]="flipCard.BackcardText | embed: 'html'"
            ></div>
        </div>
    </div>
</div>
